import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Container, Col, Row, Nav, Tab, } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { gsap } from 'gsap'
import logo from '../../../assets/regal_images/regal_logo.png'
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { IMAGE_ENDPOINT, LOCALITY_IMAGE_ENDPOINT } from '../../../constant/ApiList';
import { ROUTES } from '../../../constant/routes';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Share from '../common/Share';
import MuiAlert from '@mui/material/Alert';
import * as MovieAction from '../../../actions/MovieAction'
import * as HomeAction from '../../../actions/HomeActions'
import SwiperCore, { Autoplay, Pagination, Navigation, EffectCreative, Thumbs } from "swiper";
import { Box, Tooltip, styled } from '@mui/material';
import { EffectCoverflow } from 'swiper';

import Modal from '@mui/material/Modal';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "70vh",
    width: 400,
    bgcolor: 'rgb(25,25,25)',
    border: '2px solid rgb(255,255,255,0.5)',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
};
const styleforAlert = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "70vh",
    width: 600,
    bgcolor: 'rgb(25,25,25)',
    border: '2px solid rgb(255,255,255,0.5)',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const gsapAnimate = {
    getData: (elem) => {
        const option = {
            opacity: 0,
            scale: 1,
            position: {
                x: 0,
                y: 0,
            },
            ease: "",
            duration: 1,
            delay: .4,
            rotate: 0
        }
        if (elem !== undefined) {
            option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0)
            option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0)
            option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0)
            option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1)
            option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0)
            option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, .4)
            option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5)
            option.ease = gsapAnimate.validValue(elem.dataset.iqEase, '')
            const setOption = { opacity: option.opacity, scale: option.scale, x: option.position.x, y: option.position.y, ease: option.ease, rotate: option.rotate, duration: option.duration, delay: option.delay }
            return setOption
        } else {
            return { opacity: 0 }
        }
    },
    onStart: (elem) => {
        const setOption = gsapAnimate.getData(elem)
        gsap.from(elem, setOption)
    },
    onEnd: (elem) => {
        const setOption = gsapAnimate.getData(elem)
        gsap.to(elem, setOption)
    },

    onStartEnd: (elem) => {
        const setOption = gsapAnimate.getData(elem)
        const setEndOption = gsapAnimate.getData(elem)
        setEndOption.opacity = 1
        setEndOption.x = 0
        setEndOption.y = 0
        setEndOption.rotate = 0
        setEndOption.scale = 1
        gsap.fromTo(elem, setOption, setEndOption)
    },
    validValue: (attr, defaultVal) => {
        if (attr !== undefined && attr !== null) {
            return Number(attr)
        }
        return Number(defaultVal)
    }
}

function Slider() {
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [slug, setSlug] = useState("")
    const isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))
    const [save, setSave] = useState(false)
    const { getSliderData } = bindActionCreators(HomeAction, dispatch)
    const [popupOpen, setPopupOpen] = useState(false)
    const [selectedMovie, setSelectedMovie] = useState()
    const history = useHistory()
    const [sliderData, setSliderData] = useState([])
    SwiperCore.use([Autoplay, EffectCoverflow, Thumbs])

    useEffect(() => {
        (async () => {
            const sliderRes = await getSliderData()
            if (sliderRes?.statuscode == 200) {
                setSliderData(sliderRes?.data)
            }
        })();
    }, [save])

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const handleClose = () => {
        setAnchorEl(null);
    };

    const animationInit = () => {
        if (document.querySelector('.swiper-container .swiper-slide-active') !== null) {

            const gsapElem = document.querySelector('.swiper-container .swiper-slide-active').querySelectorAll('[data-iq-gsap="onStart"]')

            Array.from(gsapElem, (elem) => {
                return gsapAnimate.onStartEnd(elem)
            })
        }
    }

    const handleRedirect = (data) => {
        let userObj = {
            movie_name: data.slider_name,
            Trailer: data.slider_video_link
        }

        history.push(ROUTES.trailer, { data: userObj })
    }
    const handlePopupClose = () => {
        setPopupOpen(false);
    };

    return (
        <>
            <Modal
                open={popupOpen}
                onClose={handlePopupClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {!isLoggedIn ? <Box sx={style}>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={logo} width={"100px"} />
                    </div>
                    <hr />

                    <div className="login-popup" >
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                            Please login to continue.
                        </Typography>
                        <button onClick={() => history.push("/login")} className={"btn btn-hover iq-button mt-3"} style={{ display: "block", margin: "0 auto" }}>
                            Login
                        </button>
                    </div>
                </Box> :
                    (selectedMovie?.max_movie_watch_complete == "true" ?
                        <Box sx={style}>

                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <img src={logo} width={"100px"} />
                            </div>
                            <hr />

                            <div className="login-popup" >
                                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                                    You have already seen this film maximum number of times. To watch it again, purchase a Diamond Plan.
                                </Typography>
                                <div className='mt-3' style={{ display: "flex", justifyContent: "center" }}>

                                    <button onClick={handlePopupClose} className={"btn btn-hover iq-button ml-2"} >
                                        Okay
                                    </button>
                                </div>
                            </div>
                        </Box> : <Box sx={style}>

                            <div style={{ display: "flex", justifyContent: "left", fontSize: "19px", color: "red", fontWeight: "900" }}>
                                {/* <img src={logo} width={"100px"} /> */}
                                Maturity Rating: 18+
                            </div>
                            <hr />


                        </Box>)
                }
            </Modal>
            <section id="home" className="iq-main-slider p-0 iq-rtl-direction slider-cls slider-trf">
                <Swiper
                    modules={[Autoplay, Pagination, Navigation]}
                    navigation={{
                        prevEl: '#prev5',
                        nextEl: '#next5'
                    }}

                    pagination={{
                        "clickable": true
                    }}
                    autoplay={{
                        delay: 4000,
                    }}
                    onInit={() => { animationInit() }}
                    onSlideChangeTransitionStart={() => animationInit()}
                    // loop={true}
                    id="home-slider"
                    className="slider m-0 p-0">
                    {
                        sliderData?.map((data, index) => (
                            
                            <SwiperSlide
                                className="slide slick-zbg slider-responsive-class"
                                key={index}
                            >
                                <img src={LOCALITY_IMAGE_ENDPOINT + data?.web_banner} className='slider-responsive-class' style={{ height: "60vh", position: "absolute", top: "0", left: "0", width: "100%" }} />
                                <Container fluid className="position-relative h-100">
                                    <div className="slider-inner h-100">
                                        <Row className="align-items-center  iq-ltr-direction h-100 z-index-class">
                                            <Col xl="6" lg="12" md="12">
                                                <div className="d-flex align-items-start r-mb-23" style={{ marginTop: "1rem" }}>
                                                    <button className="btn btn-hover iq-button slider-btn"
                                                        onClick={() => handleRedirect(data)}
                                                    >
                                                        <i className="fa fa-play mr-2" aria-hidden="true"></i>Play
                                                    </button>

                                                    <Popover
                                                        id={id}
                                                        open={open}
                                                        anchorEl={anchorEl}
                                                        onClose={handleClose}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                    >
                                                        <Typography sx={{ p: 2 }}>
                                                            <Share sharedURL={`${window.location.href}movie-details/${slug}`} />
                                                        </Typography>
                                                    </Popover>

                                                </div>
                                            </Col>

                                        </Row>
                                    </div>
                                </Container>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </section>
        </>
    )
}
export default Slider