import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import PhoneInput from 'react-phone-input-2';
import styles from "../../../../views/backend/pages/auth/form.module.css";
import Chip from '@mui/material/Chip';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import * as HomeAction from '../../../../actions/HomeActions'
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ROUTES } from '../../../../constant/routes';
import ganeshImg from '../../../../assets/regal_images/ganesh.jpg'
export default function CompetitionForm() {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    mobile_number: '',
  })

  const [fileInputs, setFileInputs] = useState([{ id: 1, file: null }]);
  const dispatch = useDispatch()
  const { submitCompetitionForm } = HomeAction
  const history = useHistory()
  const [images, setImages] = useState([])

  console.log(formData, images)


  const handleSubmit = async (e) => {
    e.preventDefault()

    if (formData?.firstname && formData?.lastname && formData?.email && formData?.mobile_number && images?.length) {
      const formlist = new FormData()
      formlist.append("firstname", formData?.firstname)
      formlist.append("lastname", formData?.lastname)
      formlist.append("email", formData?.email)
      formlist.append("mobile_number", formData?.mobile_number)
      formlist.append("image_1", images[0])
      formlist.append("image_2", images[1] || "")
      formlist.append("image_3", images[2] || "")

      const resData = await submitCompetitionForm(formlist)
      if (resData?.statuscode == 200) {
        toast?.success(resData?.message)
        setTimeout(() => {
          history.replace(ROUTES.home)
        }, 2000)
      }
      else {
        toast?.error("Something went wrong")
      }
    }
    else {
      toast?.error("Please fill all the required fields")
    }
  }


  const addFileInput = () => {
    if (fileInputs.length == 3) {
      toast?.error("You can upload maximum 3 images")
    } else {
      setFileInputs([...fileInputs, { id: fileInputs.length + 1, file: null }]);

    }
  };

  const handleFileChange = (event, index) => {
    const newFileInputs = [...fileInputs];
    newFileInputs[index].file = event.target.files[0];
    setFileInputs(newFileInputs);
    setImages([...images, event.target.files[0]])
  };
  return (
    <div className='mt-4'>
      <section className="m-profile setting-wrapper">
        <div className='mb-5' style={{ display: "flex", justifyContent: "center" }}>
          <img src={ganeshImg} alt={'ganesh'} style={{ width: "200px" }} />
        </div>
        <Container style={{ border: "1px solid #404043", padding: "40px", borderRadius: "1rem" }}>
          <h5>Ganesh Chaturthi Competition Form</h5>


          <form onSubmit={handleSubmit}>

            <Row className='mt-3'>
              <Col md="4">
                <Form.Group>
                  {/* <Form.Label style={{ color: "white" }}>First Name</Form.Label> */}
                  <Form.Control
                    type="text"
                    className="mb-0"
                    id="exampleInputEmail2"
                    onChange={(event) => (setFormData({ ...formData, firstname: event.target.value }))}
                    placeholder="First Name"
                    autoComplete="off"

                    style={{ height: '48px' }} // Set the desired height

                  />

                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  {/* <Form.Label style={{ color: "white" }}>Last Name</Form.Label> */}
                  <Form.Control
                    type="text"
                    className="mb-0"
                    id="exampleInputEmail3"
                    onChange={(event) => (setFormData({ ...formData, lastname: event.target.value }))}
                    placeholder="Last Name"
                    autoComplete="off"

                    style={{ height: '48px' }} // Set the desired height

                  />

                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  {/* <Form.Label style={{ color: "white" }}>Last Name</Form.Label> */}
                  <Form.Control
                    type="text"
                    className="mb-0"
                    id="exampleInputEmail3"
                    onChange={(event) => (setFormData({ ...formData, email: event.target.value }))}
                    placeholder="Email"
                    autoComplete="off"

                    style={{ height: '48px' }} // Set the desired height

                  />

                </Form.Group>
              </Col>

              <Col md="4">
                <Form.Group>
                  <PhoneInput
                    labelId="demo-simple-select-label"
                    country={"in"}

                    // value={form?.phoneNumber}

                    onChange={(phone, countryCode) => {
                      setFormData({
                        ...formData,
                        mobile_number:
                          // form?.countryCode == "+" + countryCode.dialCode

                          (countryCode.dialCode == "91" ? (phone[2] >= 6 ? phone.substring(2) : countryCode.dialCode)
                            : phone.substring(2)),
                        countryCode: "+" + countryCode.dialCode,
                      });

                    }}
                    isValid={(value, country) => {
                      if (country.dialCode == "91" && value[2] < 6) {
                        return 'Invalid Number'
                      } else {
                        return true;
                      }
                    }}
                    dropdownClass={styles.phoneInputDropDown}
                    highlightClass={styles.highlight}
                    buttonClass={styles.phoneInputButton}
                    inputClass={styles.phoneInput}
                  />
                </Form.Group>
              </Col>

              <Col md="12" >
                <Row>
                  {
                    fileInputs.map((data, index) => (
                      <Col md="3">
                        <Form.Group controlId="formFile" className="mb-3" style={{ display: "flex", justifyContent: "end" }}>
                          <div id="file-upload-form" className="uploader">
                            <input id="file-upload" type="file" name="fileUpload" accept="image/*"
                              onChange={(event) => handleFileChange(event, index)}

                            />

                            <label for="file-upload" id="file-drag">
                              <img id="file-image" src="#" alt="Preview" className="hidden" />
                              <div id="start">
                                {/* <i className="fa fa-download" aria-hidden="true"></i> */}
                                <div className='mt-3'>Upload image {index + 1}</div>
                                {/* <div id="notimage" className="hidden">Please select an image</div> */}
                                <span id="file-upload-btn" className="btn btn-primary">Select Image</span><br />
                                <span id="file-upload-btn" className="">{images[index]?.name ? (images[index].name?.length <= 20 ? images[index].name : images[index].name?.substring(0, 20) + "...") : ""}</span>

                              </div>
                              <div id="response" className="hidden">
                                <div id="messages"></div>
                                <progress className="progress" id="file-progress" value="0">
                                  <span>0</span>%
                                </progress>
                              </div>
                            </label>
                          </div>
                        </Form.Group>
                      </Col>
                    ))
                  }
                  {
                    fileInputs?.length < 3 &&
                    <Col md="3" style={{ textAlign: "center" }}>
                      <div onClick={addFileInput} className="btn btn-hover">
                        +
                      </div>
                    </Col>
                  }

                </Row>
              </Col>

              <Button type='submit' style={{ display: "block", margin: "0 auto" }} className="btn btn-hover mt-3" >Submit</Button>
            </Row>
          </form>

        </Container>
      </section>
    </div>
  )
}
