import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import * as HomeAction from '../../../actions/HomeActions'
import { Grid } from '@mui/material'
import { Container } from 'react-bootstrap'
import Category from '../home/Category'
import empty from '../../../assets/regal_images/empty-box (1).png'
import { LOCALITY_IMAGE_ENDPOINT } from '../../../constant/ApiList'
import { ROUTES } from '../../../constant/routes'


export default function VideoByTags() {
    const location = useLocation()
    const { getVideoByTags } = HomeAction
    const [videos, setVideos] = useState({})
    const history = useHistory()
    useEffect(() => {
        (async () => {
            if (location?.state?.data) {
                const resData = await getVideoByTags({ id: location?.state?.data })
                console.log(resData)
                setVideos(resData)

            }
        })();
    }, [])

    return (
        <section className="m-profile setting-wrapper">
            <Container>
                <div className='movielist-dropdwn mt-3' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <h4 className="main-title mb-5 mt-3">Filtered Videos</h4>
                </div>

                <Grid container spacing={2}>
                    {
                        videos?.data?.length ?
                            videos?.data?.map((data, index) => (
                                <Grid style={{ cursor: "pointer" }} item lg={4} xl={4} md={6} sm={6} xs={12} key={index} className='grid-img-cls trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction'>
                                    <div onClick={() => history.push(ROUTES.moviedetails + '/' + data?.slug + `/image-thumb/${LOCALITY_IMAGE_ENDPOINT}${data?.video_poster}`)} className="image-container">
                                        <img src={`${LOCALITY_IMAGE_ENDPOINT + data?.video_poster}`} className="series-img" alt={data?.movie_name} />
                                        <div className="image-overlay">{data?.video_name}</div>
                                        {/* <Card className='hover-class'>
                                        <Card.Img variant="top" src={`${LOCALITY_IMAGE_ENDPOINT}/${data?.video_poster}`} />
                                        <Card.Body style={{ marginTop: "-1rem" }}>
                                            <span style={{ color: "#ffffff", fontSize: "13px" }}>{data?.movie_name}
                                            </span><br />
                                            <div style={{ marginTop: "-0.3rem" }}>
                                                <span style={{ color: "#ffffff", fontSize: "10px" }}>Duration: {data?.movie_duration}
                                                </span>
                                                <p style={{ fontSize: "10px" }} dangerouslySetInnerHTML={{ __html: data?.movie_description?.length <= 60 ? data?.movie_description : data?.movie_description?.substring(0, 60) + "..." }}></p>
                                                <div style={{ display: "flex", background: "#ffffff", color: "#000000", fontWeight: "bolder", borderRadius: "0.5rem", }}>
                                                    <div style={{ fontSize: "13px", margin: "0 auto", padding: "5px" }}><i className="fa fa-info" aria-hidden="true"></i>  Details</div>
                                                </div>
                                            </div>

                                        </Card.Body>
                                    </Card> */}
                                    </div>
                                </Grid>
                            ))
                            :
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <img src={empty} alt='empty' style={{ height: "60px" }} />
                                    <p className='pt-4'> Sorry, No result found.</p>
                                    {/* <CircularProgress /> */}
                                </div>
                            </Grid>
                    }

                </Grid>
            </Container>
        </section>
    )
}
