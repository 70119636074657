import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as HomeAction from '../../../actions/HomeActions'
import * as MovieAction from '../../../actions/MovieAction'

import { Col, Container, Dropdown, DropdownButton, Row } from 'react-bootstrap'
import Category from '../home/Category'
import { Autocomplete, Paper, TextField } from '@mui/material'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { ROUTES } from '../../../constant/routes'

export default function Locality() {

    const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
    const dispatch = useDispatch()
    const [category, setCategory] = useState([])
    const { getAllHomeData } = bindActionCreators(HomeAction, dispatch)
    const { getSubCategorySearch } = bindActionCreators(MovieAction, dispatch)
    const [searchResult, setSearchResult] = useState([])
    const { getAreaByCity } = MovieAction
    const { getAllData } = HomeAction
    const [area, setArea] = useState([])
    const [tags, setTags] = useState([])
    const history = useHistory()
    useEffect(() => {
        (async () => {
            let userObj = {
                user_id: user_id ? user_id : "null",
                profile_id: profile_id ? profile_id : "null"
            }

            const resData = await getAllHomeData(userObj)
            const areas = await getAreaByCity()
            const tag = await getAllData()
            setArea(areas?.data)
            console.log(tag)
            setTags(tag?.tags?.data)
            setCategory(resData?.data)
        })()
    }, [])

    const handleRedirect = (event, value) => {
        console.log(event, value)
        history.push(`${ROUTES.videoByArea + "/" + value?.slug}/${value?.pincode}`)

    }

    const handleRedirectByTag = (event, value) => {
        console.log(event, value)
        history.push(ROUTES.videoByTags, { data: value?.id })
    }

    const handleSearchChange = async (e) => {
        let userObj = {
            search: e.target.value
        }
        const resData = await getSubCategorySearch(userObj)
        console.log(resData)
        setSearchResult(resData?.data)
    }
    return (
        <section className="m-profile setting-wrapper" style={{ height: "60vh" }}>
            <Container>
                {/* <div style={{ textAlign: "center" }}>
                    <Row>
                        <Col md="8" className='mt-2 mb-3'> */}
                <div className='mt-1 mb-5 responsive-search' style={{ width: "70%", margin: "auto" }}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={searchResult}
                        getOptionLabel={(option) => option?.locality_name + ` - ${option?.pincode}`}

                        onChange={(event, value) => handleRedirect(event, value)}
                        sx={{

                            borderRadius: "1rem",

                        }}
                        PaperComponent={({ children }) => (
                            <Paper style={{ backgroundColor: 'black', color: "#ffffff" }}>{children}</Paper>
                        )}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label="Enter your area name or pincode"
                                onChange={(e) => handleSearchChange(e)}
                                inputProps={{
                                    ...params.inputProps,
                                    style: { borderColor: 'white', color: 'white' }, // Change border and value color
                                }}
                                InputLabelProps={{
                                    ...params.InputLabelProps,
                                    style: { color: 'white' }, // Change label color
                                }}
                            />}
                    />
                    <div className='mt-5 mb-5' style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <span>-------</span>
                        <span className='ml-3 mr-3'>or</span>
                        <span>-------</span>
                    </div>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={tags}
                        getOptionLabel={(option) => option?.tag_title}

                        onChange={(event, value) => handleRedirectByTag(event, value)}
                        sx={{

                            borderRadius: "1rem",

                        }}
                        PaperComponent={({ children }) => (
                            <Paper style={{ backgroundColor: 'black', color: "#ffffff" }}>{children}</Paper>
                        )}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label="Enter tag name"
                                // onChange={(e) => handleSearchChange(e)}
                                inputProps={{
                                    ...params.inputProps,
                                    style: { borderColor: 'white', color: 'white' }, // Change border and value color
                                }}
                                InputLabelProps={{
                                    ...params.InputLabelProps,
                                    style: { color: 'white' }, // Change label color
                                }}
                            />}
                    />
                </div>


            </Container>
            {/* {
                category?.map((data, index) => (
                    <div key={index}>
                        <Category category={data} />
                    <></>
                    </div>
                ))
            } */}



        </section>
    )
}
