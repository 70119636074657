import { param } from 'jquery';
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import * as MovieAction from '../../../actions/MovieAction'
import { Container, Dropdown, DropdownButton } from 'react-bootstrap';
import { Grid } from '@mui/material';
import { LOCALITY_IMAGE_ENDPOINT } from '../../../constant/ApiList';
import empty from '../../../assets/regal_images/empty-box (1).png'
import { ROUTES } from '../../../constant/routes';
import Category from '../home/Category';

export default function VideoByArea() {
    const prams = useParams()
    const { getVideosByArea } = MovieAction
    const [videos, setVideos] = useState({})
    const [name, setName] = useState("Filter by Category")

    const [filtered, setFiltered] = useState([])
    const [isFiltered, setIsFiltered] = useState(false)
    const history = useHistory()
    useEffect(() => {
        (async () => {
            if (prams?.slug) {
                const resData = await getVideosByArea(prams?.slug)
                console.log(resData)
                setVideos(resData)
            }
        })();
    }, [prams?.slug])

    const handleAccessFilter = (data) => {
        setName(data?.category_name)
        setIsFiltered(true)
        // console.log(data)
        // console.log(videos?.data)
        videos?.data?.filter((cat_data) => {
            if (cat_data?.category_name == data?.category_name) {
                // console.log(cat_data)
                setFiltered([cat_data])
            }
        })

    }

    const handleClear = () => {
        setIsFiltered(false)
        setName("Filter by Category")
    }

    return (
        <>
            <section className="m-profile setting-wrapper" id='my-id'>
                <Container style={{ display: "flex", justifyContent: "space-between" }}>
                    <h4 className="main-title mb-5 mt-3">Filtered Videos</h4>
                    <DropdownButton id="dropdown-item-button" title={name}>
                        <div style={{ maxHeight: '200px', overflowY: 'auto', background: "#000000" }} className='remove-scroll'>
                            <Dropdown.Item style={{ cursor: "pointer", color: "white" }} as="option" value={'SVOD'} onClick={() => handleClear()}>
                                ALL
                            </Dropdown.Item>
                            {
                                videos?.category_data?.map((data, index) => (
                                    <Dropdown.Item key={index} style={{ cursor: "pointer", color: "white" }} as="option" value={'SVOD'} onClick={() => handleAccessFilter(data)}>
                                        {data?.category_name}
                                    </Dropdown.Item>
                                ))
                            }
                        </div>
                    </DropdownButton>
                </Container>

                {
                    !isFiltered ?
                        videos?.data?.length ?
                            videos?.data?.map((data, index) => (

                                <div key={index}>
                                    <Category category={data} />

                                </div>

                            ))
                            :
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <img src={empty} alt='empty' style={{ height: "60px" }} />
                                    <p className='pt-4'> Sorry, No result found.</p>
                                    {/* <CircularProgress /> */}
                                </div>
                            </Grid>
                        :
                        filtered?.length ?
                            filtered?.map((data, index) => (

                                <div key={index}>
                                    <Category category={data} />

                                </div>

                            ))
                            :
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <img src={empty} alt='empty' style={{ height: "60px" }} />
                                    <p className='pt-4'> Sorry, No result found.</p>
                                    {/* <CircularProgress /> */}
                                </div>
                            </Grid>
                }


            </section>
        </>
    )
}
