import React, { useMemo, useState, useEffect, useRef } from 'react'
import { Container, Row, Col, Navbar, Dropdown, Form, Nav } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import Card from '../../../../components/Card'
import axios from "axios";
import CustomToggle from '../../../../components/dropdowns'
//img
import { logout, notification_read_status_update, user_login_status, user_notification } from '../../../../actions/HomeActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { user_profile_list } from "../../../../actions/HomeActions"
import addProfile from "../../../../assets/images/addProfile.png"
import logo from '../../../../assets/regal_images/regal_logo.png';
import pwa from '../../../../assets/regal_images/pwa.png';
import user from '../../../../assets/regal_images/top_right_user_icon.png'
import { ROUTES } from '../../../../constant/routes'
import { IMAGE_ENDPOINT } from '../../../../constant/ApiList';
import * as MovieAction from '../../../../actions/MovieAction'
import myAcc from '../../../../assets/regal_images/My Account.png'
import watchlatter from '../../../../assets/regal_images/Watch Later.png'
import complaintImg from '../../../../assets/regal_images/complain.png'
import logoutImg from '../../../../assets/regal_images/exit.png'
import LoginImg from '../../../../assets/regal_images/enter.png'
import AlertMessage from '../../../../views/backend/common/AlertMessage';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MailIcon from '@mui/icons-material/Mail';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { LOGOUT_MESSAGE } from '../../../../Actiontype/ActionType';
import { Badge, Grid } from '@mui/material';
import competitionImg from '../../../../assets/regal_images/ganesh.jpg';
import myZip from '../../../../regal_ott-win32-x64.zip'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { bindActionCreators } from 'redux';
import * as HomeAction from '../../../../actions/HomeActions'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "100vh",
    width: 400,
    bgcolor: 'rgb(25,25,25)',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
};
const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "100vh",
    width: 600,
    bgcolor: 'rgb(25,25,25)',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
};
const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "100vh",
    width: 1000,
    bgcolor: 'rgb(25,25,25)',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
};


const HeaderStyle1 = (props) => {
    const [open, setOpen] = React.useState(false);
    var isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
    const user_id_local = JSON.parse(sessionStorage.getItem('user'))?.id
    const user_data = JSON.parse(sessionStorage.getItem('user'))
    const [form, setForm] = useState({ user: user_id || user_id_local, user_id: user_id || user_id_local, email: user_data?.email })
    const [showNotification, setShowNotification] = useState()
    const [openLogoutDialogue, setLogoutDialogue] = useState(false)
    const [formDialogue, setFormDialogue] = useState(false)

    // const genres = useSelector((state) => state?.home?.homeData?.data?.Genre)
    // const languages = useSelector((state) => state?.home?.homeData?.data?.Language)
    const [languages, setLanguages] = useState([])
    const [genres, setGenres] = useState([])


    const [searchText, setSearchText] = useState('');
    const [isListening, setIsListening] = useState(false);
    const [download, setDownload] = useState(false);

    const [ip, setIP] = useState("")
    const dispatch = useDispatch()
    let history = useHistory()
    const location = useLocation()
    const { getSubCategorySearch } = bindActionCreators(MovieAction, dispatch)
    const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
    const dropdownRef = useRef(null);
    const dropdownRef1 = useRef(null);
    const { getAllHomeData } = bindActionCreators(HomeAction, dispatch)
    useEffect(() => {
        const handleBeforeInstallPrompt = (event) => {
            event.preventDefault(); // Prevent Chrome from automatically showing the prompt
            setInstallPromptEvent(event); // Store the event for later use
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);
    const getData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        setIP(res.data.ip)
        setForm({ ...form, ip_address: res.data.ip, user_id: user_id });
    };
    useEffect(() => {
        getData();
    }, []);
    useEffect(() => {
        (async () => {
            if (isLoggedIn && user_id) {
                const res = await axios.get("https://api.ipify.org/?format=json");
                let userObj = {
                    ip_address: res.data.ip,
                    user_id: user_id
                }
                dispatch(user_login_status(userObj))
            }
            isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))
            // dispatch(getAllHomeData({ user_id: user_id || null, profile_id: profile_id || null }))
            // const languageResult = await getAllHomeData({ user_id: user_id || "null", profile_id: profile_id || "null" })
            // setGenres(languageResult?.data?.Genre)
            // setLanguages(languageResult?.data?.Language)

        })();

    }, [location?.pathname]);


    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false);
        }
        if (dropdownRef1.current && !dropdownRef1.current.contains(e.target)) {
            setIsOpen1(false)
        }
    };

    const handleMouseMove = (event) => {
        // Check if the mouse cursor is outside the component's boundaries


    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('click', handleClickOutside);
            document.removeEventListener('mousemove', handleMouseMove);

        };
    }, []);
    const [installPromptEvent, setInstallPromptEvent] = useState(null);




    const [search, setSearch] = useState(null)
    const [searchValue, setSearchValue] = useState("")

    const [read, setRead] = useState(0)
    const profiles = useSelector((state) => state.home.profiles);
    const logout_message = useSelector((state) => state.home.logout_message);
    const notifications = useSelector((state) => state.home.notifications);
    useMemo(() => {
        if (logout_message?.statuscode == 200) {
            isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))
            toast.success(logout_message?.message)
            dispatch({ type: LOGOUT_MESSAGE, payload: null })

        }

    }, [logout_message])




    const handleClose = () => {
        setOpen(false)
        setShowNotification()
        setLogoutDialogue(false)
    };

    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);


    const handleInstallClick = () => {
        if (installPromptEvent) {
            installPromptEvent.prompt(); // Show the install prompt
            installPromptEvent.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
                setInstallPromptEvent(null); // Reset the event
            });
        }

    };
    return (
        <>
            <AlertMessage />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div>
                    {!isLoggedIn && <Box sx={style}>

                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <img src={logo} width={"100px"} />
                        </div>
                        <hr />

                        <div className="login-popup" >
                            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                                Please login to continue.
                            </Typography>
                            <button onClick={() => history.push("/login")} className={"btn btn-hover iq-button mt-3"} style={{ display: "block", margin: "0 auto" }}>
                                Login
                            </button>
                        </div>
                    </Box>}
                    {showNotification && <Box sx={style}><Card sx={{ maxWidth: 345 }}>
                        <Card.Header className="flex-column">
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Typography variant="h5" component="h5" >{showNotification?.title}</Typography>

                                    <Typography variant="body2" >{showNotification?.date}</Typography>

                                </div>
                                <div style={{ display: "flex", justifyContent: "end", cursor: "pointer" }}>
                                    <i style={{ fontSize: "30px" }} className="ri-close-fill" onClick={() => setOpen(false)}></i>
                                </div>
                            </div>

                            <hr style={{ color: "white", width: "100%" }} />
                        </Card.Header>



                        <Card.Body>

                            <Grid container spacing={2}>

                                <Grid item xs={4}>
                                    {showNotification?.image && <img src={showNotification?.image} style={{ maxHeight: "200px" }} />}
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography>
                                        {showNotification?.description}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card.Body>



                    </Card></Box>}</div>
            </Modal>
            <header id="main-header" className='header-width'>
                <div className="main-header">
                    <Container fluid>
                        <Row>
                            <Col sm="12">
                                <Navbar expand="lg" className="p-0">
                                    <Navbar.Toggle className="c-toggler">
                                        <div className="navbar-toggler-icon"  >
                                            <span className="navbar-menu-icon navbar-menu-icon--top"></span>
                                            <span className="navbar-menu-icon navbar-menu-icon--middle"></span>
                                            <span className="navbar-menu-icon navbar-menu-icon--bottom"></span>
                                        </div>
                                    </Navbar.Toggle>
                                    <Navbar.Brand className="navbar-brand">
                                        <Link to="/"><img className="img-fluid logo my-logo-class" src={logo} alt="regal-ott" style={{ width: "150px" }} /></Link>
                                    </Navbar.Brand>
                                    <Dropdown className="mobile-more-menu">
                                        <Dropdown.Toggle to="#" as={CustomToggle} variant="more-toggle">
                                            <i className="ri-more-line"></i>
                                        </Dropdown.Toggle>
                                        {/* <Dropdown.Menu className="more-menu">
                                            <div className="navbar-right position-relative">
                                                <ul className="d-flex align-items-center justify-content-end list-inline m-0">
                                                    <Dropdown as="li" className="nav-item m-">
                                                        <div className="cfield">
                                                            <input
                                                                type="text"
                                                                name="search"
                                                                size="20"
                                                                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                aria-required="true"
                                                                aria-invalid="false"
                                                                placeholder="I am searching for..."
                                                                onChange={(event) => handleSearchChange(event)}
                                                                autoComplete="off"
                                                            />
                                                            <div className={search !== null ? "displayItems" : "displaynone"}>
                                                                {
                                                                    search?.length ?

                                                                        search?.map((data, index) => (
                                                                            <ul key={index} style={{ color: "#ffffff" }}>
                                                                                <div style={{ cursor: "pointer" }} onClick={() => handleVideos(data)}><li>{data?.locality_name}</li></div>
                                                                            </ul>
                                                                        ))
                                                                        :
                                                                        <p style={{ color: "#ffffff", textAlign: "center" }}>No result found.</p>

                                                                }
                                                            </div>
                                                        </div>
                                                       
                                                    </Dropdown>
                                                    <Dropdown as="li" className="nav-item m-0">
                                                        <Dropdown.Toggle href="#" as={CustomToggle} variant="search-toggle position-relative">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"
                                                                className="noti-svg">
                                                                <path fill="none" d="M0 0h24v24H0z" />
                                                                <path
                                                                    d="M18 10a6 6 0 1 0-12 0v8h12v-8zm2 8.667l.4.533a.5.5 0 0 1-.4.8H4a.5.5 0 0 1-.4-.8l.4-.533V10a8 8 0 1 1 16 0v8.667zM9.5 21h5a2.5 2.5 0 1 1-5 0z" />
                                                            </svg>
                                                            <span className="bg-danger dots"></span>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="iq-sub-dropdown dropdown-menu dropdown-menu-right">
                                                            <Card className="iq-card shadow-none m-0">
                                                                <Card.Body className="iq-card-body">
                                                                    <Link to="#" className="iq-sub-card">
                                                                        <div className="media align-items-center">
                                                                            <img src={thumb1} className="img-fluid mr-3"
                                                                                alt="streamit" />
                                                                            <div className="media-body">
                                                                                <h6 className="mb-0 ">Boot Bitty</h6>
                                                                                <small className="font-size-12"> just now</small>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                    <Link to="#" className="iq-sub-card">
                                                                        <div className="media align-items-center">
                                                                            <img src={thumb2} className="img-fluid mr-3"
                                                                                alt="streamit" />
                                                                            <div className="media-body">
                                                                                <h6 className="mb-0 ">The Last Breath</h6>
                                                                                <small className="font-size-12">15 minutes ago</small>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                    <Link to="#" className="iq-sub-card">
                                                                        <div className="media align-items-center">
                                                                            <img src={thumb3} className="img-fluid mr-3"
                                                                                alt="streamit" />
                                                                            <div className="media-body">
                                                                                <h6 className="mb-0 ">The Hero Camp</h6>
                                                                                <small className="font-size-12">1 hour ago</small>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </Card.Body>
                                                            </Card>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </ul>
                                            </div>
                                        </Dropdown.Menu> */}
                                    </Dropdown>
                                    <Navbar.Collapse className="">
                                        <div className="">
                                            {/* <div className="menu-main-menu-container"> for center navbar*/}

                                            <Nav as="ul" id="top-menu" className="ml-auto">
                                                {
                                                    !isLoggedIn && <li className="menu-item">
                                                        <Link to={'/login'}>Sign In</Link>
                                                    </li>
                                                }
                                                {
                                                    !isLoggedIn && <li className="menu-item">
                                                        <Link to={'/sign-up'}>Sign Up</Link>
                                                    </li>
                                                }


                                                {/* <li className="menu-item">
                                                    <Link to={ROUTES.movies}>Videos</Link>
                                                </li> */}
                                                {/* <li className="menu-item">
                                                    <Link to={ROUTES.categories}>Categories</Link>
                                                </li> */}
                                                <li className="menu-item">
                                                    <Link to={ROUTES.locality}>Locality</Link>
                                                </li>


                                            </Nav>
                                        </div>
                                    </Navbar.Collapse>

                                    <div className="navbar-right menu-right">
                                        <ul className="d-flex align-items-center list-inline m-0">

                                            {/* <div className='search-panel-responsive mr-2'>
                                                <Dropdown as="li" className="nav-item nav-icon search-set">
                                                    <Dropdown.Toggle as={CustomToggle} href="#" variant="search-toggle device-search">
                                                        <div className='search-box-panel'>
                                                            <input
                                                                // autoFocus
                                                                type="text"
                                                                name="search-value"
                                                                size="50"
                                                                value={searchValue}
                                                                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                aria-required="false"
                                                                aria-invalid="false"
                                                                placeholder="I am searching for..."
                                                                onChange={(event) => handleSearchChange(event)}
                                                                autoComplete={"off"}
                                                            />
                                                        </div>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="p-0 m-0 mt-3" align="right">

                                                        <div className="cfield">

                                                            <div className={`hide-scrolls ${search !== null ? "displayItems" : "displaynone"}`}>
                                                                {
                                                                    search?.length ?

                                                                        search?.map((data, index) => (
                                                                            <ul key={index} className='pl-3 hover-bg-change' style={{ color: "#ffffff", padding: "9px" }} >
                                                                                <div className='hover-bg-change' style={{ cursor: "pointer" }} onClick={() => handleVideos(data)}><li>{data?.locality_name}</li></div>
                                                                            </ul>
                                                                        ))
                                                                        :
                                                                        <p style={{ color: "#ffffff", textAlign: "center" }}>No result found.</p>

                                                                }
                                                            </div>
                                                        </div>
                                                    </Dropdown.Menu>

                                                </Dropdown>
                                            </div> */}
                                            {/* <div onClick={startListening} className='mr-2' style={{ color: "white" }} disabled={isListening}>
                                                <i className="fa fa-microphone" aria-hidden="true"></i>
                                            </div> */}
                                            {/* <div className='search-icon-responsive pr-3'>
                                                <Dropdown as="li" className="nav-item nav-icon search-set">
                                                    <Dropdown.Toggle as={CustomToggle} href="#" variant="search-toggle device-search">
                                                        <i className="ri-search-line"></i>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className="p-0 m-0 on_hover_openable" align="right">
                                                        <div className="cfield">
                                                            <div className='search-box-panel' style={{ borderRadius: "0.5rem" }}>
                                                                <input
                                                                    autoFocus
                                                                    type="text"
                                                                    name="search"
                                                                    size="10"
                                                                    className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                    aria-required="true"
                                                                    aria-invalid="false"
                                                                    placeholder="I am searching for..."
                                                                    onChange={(event) => handleSearchChange(event)}
                                                                    autoComplete="off"
                                                                />
                                                            </div>
                                                            <div className={`hide-scrolls ${search !== null ? "displayItems" : "displaynone"}`}>
                                                                {
                                                                    search?.length ?

                                                                        search?.map((data, index) => (
                                                                            <ul key={index} className='pl-3 hover-bg-change' style={{ color: "#ffffff", padding: "9px" }} >
                                                                                <div style={{ cursor: "pointer" }} onClick={() => handleVideos(data)}><li>{data?.locality_name}</li></div>
                                                                            </ul>
                                                                        ))
                                                                        :
                                                                        <p style={{ color: "#ffffff", textAlign: "center" }}>No result found.</p>

                                                                }
                                                            </div>
                                                        </div>
                                                    </Dropdown.Menu>

                                                </Dropdown>
                                            </div> */}



                                            <Dropdown as="li" className="nav-item nav-icon">
                                                <Dropdown.Toggle href="#" as={CustomToggle} variant="profile-toggle">
                                                    <div className="iq-user-dropdown search-toggle p-0 d-flex align-items-center active"
                                                        data-toggle="search-toggle">
                                                        <img src={user} className="img-fluid avatar-40" alt="user" style={{ borderRadius: ".8rem" }} />
                                                    </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="iq-sub-dropdown iq-user-dropdown align-menu-right on_hover_openable" id="user_menu" onMouseLeave={(e) => { document.getElementById("user_menu").classList.remove("show") }} align="right">
                                                    <Card className="shadow-none m-0">
                                                        {isLoggedIn && <Card.Header>
                                                            {profiles?.data?.map((ele, index) => index <= 3 && <div key={index} style={{ margin: "0.5rem" }} >

                                                                <img src={IMAGE_ENDPOINT + ele?.avatar?.avatarImage} onClick={() => {
                                                                    if (JSON.parse(ele?.profile_secure) && ele?.id !== profile_id)
                                                                        history.push("/profile_pin", { profile: { ...ele, type: "profile_select" } })
                                                                    else {
                                                                        sessionStorage.setItem("profile", JSON.stringify(ele))
                                                                        history.push("/")
                                                                    }
                                                                }
                                                                } style={{ maxWidth: "50px", marginBottom: "0", cursor: "pointer" }} className="rounded img-fluid d-block mx-auto " alt="user" />
                                                                <p style={{
                                                                    textAlign: "center",
                                                                    color: JSON.parse(sessionStorage.getItem("profile"))?.id == ele?.id ? "white" : "grey",
                                                                    fontSize: "9px", marginBottom: "0"
                                                                }}>{ele?.profileName || "No Name"}</p>
                                                            </div>)}
                                                            {Array(4).fill("a").map((ele, index) => index <= 3 - profiles?.data?.length && <div key={index} style={{ margin: ".5rem" }} >
                                                                <img src={addProfile} onClick={() => (history.push("/profile"))} style={{ maxWidth: "50px", marginBottom: "0", cursor: "pointer" }} className="rounded img-fluid d-block mx-auto" alt="user" />
                                                                <p style={{ textAlign: "center", color: "grey", fontSize: "9px", marginBottom: "0" }}>Add profile</p>
                                                            </div>)}
                                                        </Card.Header>}
                                                        {/* <div className='d-flex justify-content-center' >
                                                        {isLoggedIn &&<button  onClick={()=>(history.push("/profile_manage"))} className="btn btn-hover iq-button">Manage Profiles</button>}

                                                        </div>  */}
                                                        <Card.Body className="p-0 pl-3 pr-3 ">
                                                            {/* {!isLoggedIn && <Link to="/login" className="iq-sub-card setting-dropdown">
                                                                <div className="media align-items-center">
                                                                    <div className="right-icon">
                                                                        <img src={LoginImg} alt='acc' className='menu-img' />
                                                                    </div>
                                                                    <div className="media-body ml-3">
                                                                        <p className="my-0 ">Login / Register</p>
                                                                    </div>
                                                                </div>
                                                            </Link>} */}
                                                            {
                                                                isLoggedIn &&
                                                                <div onClick={() => isLoggedIn ? history.push(ROUTES.my_account) : setOpen(true)} className="iq-sub-card setting-dropdown">
                                                                    <div className="media align-items-center">
                                                                        <div className="right-icon">
                                                                            {/* <i className="ri-file-user-line text-primary"></i> */}
                                                                            <img src={myAcc} alt='acc' className='menu-img' />
                                                                        </div>
                                                                        <div className="media-body ml-3">
                                                                            <p className="my-0 ">My Profile</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }

                                                            {/* <div onClick={() => isLoggedIn ? history.push(ROUTES.watchlist) : setOpen(true)} className="iq-sub-card setting-dropdown">
                                                                <div className="media align-items-center">
                                                                    <div className="right-icon">
                                                                        <img src={watchlatter} alt='acc' className='menu-img' />
                                                                    </div>
                                                                    <div className="media-body ml-3">
                                                                        <p className="my-0 ">Watch Later</p>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                            <div onClick={() => isLoggedIn ? history.push(ROUTES.complaint) : setOpen(true)} className="iq-sub-card setting-dropdown">
                                                                <div className="media align-items-center">
                                                                    <div className="right-icon">
                                                                        <img src={complaintImg} alt='acc' className='menu-img' />
                                                                    </div>
                                                                    <div className="media-body ml-3">
                                                                        <p className="my-0 ">Complaint</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div onClick={() => history.push(ROUTES.digitalForm)} className="iq-sub-card setting-dropdown">
                                                                <div className="media align-items-center">
                                                                    <div className="right-icon">
                                                                        <img src={watchlatter} alt='acc' className='menu-img' />
                                                                    </div>
                                                                    <div className="media-body ml-3">
                                                                        <p className="my-0 ">Digital Content Submission</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div onClick={() => history.push(ROUTES.competitionForm)} className="iq-sub-card setting-dropdown">
                                                                <div className="media align-items-center">
                                                                    <div className="right-icon">
                                                                        <img src={competitionImg} alt='acc' className='menu-img' />
                                                                    </div>
                                                                    <div className="media-body ml-3">
                                                                        <p className="my-0 ">Ganesh Chaturthi Competition Form</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* <Link to="/login" className="iq-sub-card setting-dropdown">
                                                                <div className="media align-items-center">
                                                                    <div className="right-icon">
                                                                        <i className="ri-logout-circle-line text-primary"></i>
                                                                    </div>
                                                                    <div className="media-body ml-3">
                                                                        <h6 className="my-0 ">Settings</h6>
                                                                    </div>
                                                                </div>
                                                            </Link> */}
                                                            {/* <Link to={ROUTES.contact} className="iq-sub-card setting-dropdown">
                                                                <div className="media align-items-center">
                                                                    <div className="right-icon">
                                                                        <i className="ri-logout-circle-line text-primary"></i>
                                                                    </div>
                                                                    <div className="media-body ml-3">
                                                                        <h6 className="my-0 ">Contact Us</h6>
                                                                    </div>
                                                                </div>
                                                            </Link> */}

                                                            {isLoggedIn && <Link to="/"
                                                                // onClick={() => { dispatch(logout(form)) }}
                                                                onClick={() => setLogoutDialogue(true)}
                                                                className="iq-sub-card setting-dropdown">
                                                                <div className="media align-items-center">
                                                                    <div className="right-icon">
                                                                        <img src={logoutImg} alt='acc' className='menu-img' />
                                                                    </div>
                                                                    <div className="media-body ml-3">
                                                                        <p className="my-0 ">Sign Out</p>
                                                                    </div>
                                                                </div>
                                                            </Link>}
                                                        </Card.Body>
                                                    </Card>
                                                </Dropdown.Menu>

                                            </Dropdown>
                                            <div onClick={handleInstallClick} href="#" style={{ cursor: "pointer" }} className=' pl-2' variant="search-toggle position-relative">
                                                {/* <BrowserUpdatedIcon  color="action" sx={{ color: "#cfcecd" }} /> */}
                                                <img src={pwa} className="pwa-class" alt="user" style={{ borderRadius: "1.3rem", width: "90px", height: "55px" }} />
                                                {/* <a href={myZip}><BrowserUpdatedIcon color="action" sx={{ color: "#cfcecd" }} /></a> */}

                                            </div>
                                        </ul>

                                    </div>

                                </Navbar>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Modal
                    open={openLogoutDialogue}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <img src={logo} alt='regal_logo' style={{ width: "100px" }} />
                        </div>
                        <hr />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Are you sure you want to logout?
                        </Typography>
                        <div className='pt-3' style={{ display: "flex", justifyContent: "flex-end" }}>
                            <button className="btn btn-hover iq-button" onClick={() => { dispatch(logout({ ip_address: form?.ip_address, user: user_id || user_id_local, user_id: user_id || user_id_local, email: user_data?.email })); setLogoutDialogue(false) }}>
                                Yes
                            </button>
                            <button onClick={handleClose} className="btn btn-hover iq-button ml-2">
                                No
                            </button>
                        </div>
                    </Box>
                </Modal>



            </header>
        </>
    )
}

export default HeaderStyle1
