import React, { useState, useEffect, useMemo } from 'react'
import { Container, Button, Row, Col, Form } from 'react-bootstrap'
import InputGroup from 'react-bootstrap/InputGroup';

import { Link, useHistory } from 'react-router-dom'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import FormControl from "@mui/material/FormControl";
import styles from "./form.module.css";
import "./form.css"
// rtl 
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { rtlModeAction, getRtlMode } from '../../../../store/mode/rtlmode'
import { useDispatch } from 'react-redux';
import { register, otp, resend_otp } from "../../../../actions/HomeActions"
import { useSelector } from 'react-redux';
import axios from "axios";
import OtpInput from 'react-otp-input';
import bg from "../../../../assets/images/signup_background.jpg"
import Alert from 'react-bootstrap/Alert';
import { Autocomplete, CircularProgress, Paper, TextField } from '@mui/material';
import { ROUTES } from '../../../../constant/routes';
import { toast } from 'react-toastify';
let City = require('country-state-city').City;
let State = require('country-state-city').State;

const mapStateToProps = (state) => {
    return {
        rtlMode: getRtlMode(state)
    };


}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            rtlModeAction
        },
        dispatch
    )
})
const SignUp = (props) => {
    const dispatch = useDispatch()
    const [form, setForm] = useState({})
    const [messageTimeout, setMessageTimeout] = useState(false)
    const [seconds, setSeconds] = useState(0);
    const[selectedState,setSelectedState] = useState({})
    const resendOTPDuration = 60
    useEffect(() => {
        // Exit early if countdown is finished
        if (seconds <= 0) {
            return;
        }

        // Set up the timer
        const timer = setInterval(() => {
            setSeconds((prevSeconds) => prevSeconds - 1);
        }, 1000);

        // Clean up the timer
        return () => clearInterval(timer);
    }, [seconds]);
    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60)
            .toString()
            .padStart(2, '0');
        const seconds = (timeInSeconds % 60).toString().padStart(2, '0');
        return `${minutes}:${seconds}`;
    };
    let history = useHistory()
    const [show, setShow] = useState(false);

    useEffect(() => {
        const rtlMode = sessionStorage.getItem('rtl-mode');
        if (rtlMode === null) {
            props.rtlModeAction(props.rtlMode)
        }
        else {
            props.rtlModeAction(rtlMode);
        }
    })
    const register_message = useSelector((state) => state.home.register_message);
    const otp_message = useSelector((state) => state.home.otp_message);

    const [fieldFlag, setFieldFlag] = useState({ email: false, firstName: false, lastName: false, password: false });
    const [loader, setLoader] = useState(false)
    const getData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        console.log(res.data);
        setForm({ ...form, ip_address: res.data.ip });
    };
    useEffect(() => {
        getData();
    }, []);
    const handleRegistrationSubmit = (event) => {
        event.preventDefault();

        if (form?.email == undefined || form?.email == "" || !form?.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            setFieldFlag({ ...fieldFlag, email: true })
        }

        else if (form?.firstName == undefined || form?.firstName == "") {
            setFieldFlag({ ...fieldFlag, email: false, firstName: true })

        }
        else if (form?.lastName == undefined || form?.lastName == "") {
            setFieldFlag({ ...fieldFlag, email: false, firstName: false, lastName: true })

        }

        else if (form?.password == undefined || form?.password == "") {
            setFieldFlag({ ...fieldFlag, email: false, firstName: false, lastName: false, birthDate: false, password: true })

        }
        else if (form?.password !== form?.rpassword) {
            // setFieldFlag({ ...fieldFlag, email: false, firstName: false, lastName: false, birthDate: false, password: true })
            toast.error("Password and confirm password must be same")
        }
        else {
            dispatch(register({ ...form, username: form?.mobileNumber }));
            setLoader(true)
        }

    }

    const [otpVerification, setOtpVerification] = useState(false);
    useMemo(() => {

        setMessageTimeout(true)
        if (register_message?.statuscode == 200) {
            setLoader(false)

            setOtpVerification(true)
            setSeconds(resendOTPDuration)
        }
        else {
            setLoader(false)

        }

        setTimeout(() => { setMessageTimeout(false) }, 2000)
    }, [register_message])
    useMemo(() => {

        setMessageTimeout(true)
        if (otp_message?.statuscode == 200 && form?.resend == false) {
            console.log("Prolife selecton page")
            history.push('/login')
        }
        setTimeout(() => { setMessageTimeout(false) }, 2000)


    }, [otp_message])
    const handleOTPSubmit = (event) => {
        event.preventDefault();
        console.log(register_message)
        // sessionStorage.setItem("user", JSON.stringify({ id: register_message?.User_ID, mobileNumber: register_message?.mobileNumber, email: register_message?.email,birthDate:register_message?.birth_date }))
        // sessionStorage.setItem("isLoggedIn", true)
        setForm({ ...form, resend: false })
        setTimeout(() => {
            dispatch(otp({ ...form, mobileNumber: register_message?.mobileNumber, email: register_message?.email }));
        }, 1000);
    }
    const handleResendOTPSubmit = (event, resend) => {
        event.preventDefault();

        dispatch(resend_otp({ ...form, username: form?.mobileNumber }));
        setSeconds(resendOTPDuration)
        setForm({ ...form, resend: resend })
    }
    // const gujaratState = State.getStatesOfCountry('IN').find(state => state.name === 'Gujarat');
    const stateCity = selectedState ? City.getCitiesOfState('IN',selectedState.isoCode) : [];
    // console.log(gujaratCities);
    
    return (
        <>

            <section className="sign-in-page">

                <Container >
                    <Row className="justify-content-center align-items-center height-self-center">
                        <Col lg="7" md="12" className="align-self-center">
                            <div className="sign-user_card ">
                                <div className="sign-in-page-data">
                                    <div className="sign-in-from w-100 m-auto">
                                        {otpVerification ? <Form onSubmit={(event) => handleOTPSubmit(event)} >
                                            <Row style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>


                                                <Col md="6" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <Form.Group >
                                                        <Form.Label style={{ display: "flex", justifyContent: "center", color: "white", marginBottom: "2rem" }}>Enter the 4 digit OTP</Form.Label>
                                                        <FormControl fullWidth >
                                                            <OtpInput
                                                                containerStyle={{ display: "flex", justifyContent: "space-evenly" }}
                                                                value={form?.OTP}
                                                                onChange={(a) => (setForm({ ...form, OTP: a }))}
                                                                numInputs={4}
                                                                inputStyle={{ fontSize: "3em", padding: "0", border: "none", borderRadius: "0", borderBottom: "2px solid white", background: "transparent" }}
                                                                inputType='text'
                                                                renderSeparator={<span> &nbsp;&nbsp; </span>}
                                                                renderInput={(props) => <input {...props} />}
                                                            />
                                                            {(form?.OTP == "" || form?.OTP == undefined) && <Form.Control.Feedback style={{ display: "flex", justifyContent: "center", textAlign: "center" }} type="invalid">
                                                                Please enter the OTP you have received on Email id/Mobile No
                                                            </Form.Control.Feedback>}</FormControl>
                                                    </Form.Group>
                                                </Col>
                                                {seconds > 0 ? <p>Resend OTP in {formatTime(seconds)}</p> : <p style={{ cursor: "pointer" }} onClick={(event) => {
                                                    handleResendOTPSubmit(event, true)
                                                }} >Resend OTP</p>}

                                            </Row>
                                            {otp_message?.message && messageTimeout && (otp_message?.statuscode == 200 ?
                                                <Alert variant="success" style={{ background: "rgb(0,255,0,0.1)", borderColor: "rgb(0,255,0)" }} >
                                                    <p style={{ color: "rgb(0,255,0)", fontWeight: "500", fontSize: "14px" }}>
                                                        {otp_message?.message}
                                                    </p>
                                                </Alert>
                                                : <Alert variant="danger" style={{ background: "rgb(255,0,0,0.1)", borderColor: "rgb(255,0,0)" }} >
                                                    <p style={{ color: "rgb(255,0,0)", fontWeight: "500", fontSize: "14px" }}>
                                                        {otp_message?.message}
                                                    </p>
                                                </Alert>)}
                                            <div style={{ display: "flex", justifyContent: "space-around", gap: "2rem" }}>


                                                <Button type='submit' className="btn btn-hover" >Submit</Button>
                                            </div>


                                        </Form> : <Form onSubmit={(event) => handleRegistrationSubmit(event)}>
                                            <Row>
                                                <Col md="6">
                                                    <Form.Group>
                                                        <Form.Label style={{ color: "white" }}>First Name</Form.Label>
                                                        <Form.Control type="text" isInvalid={fieldFlag.firstName} onChange={(event) => (setForm({ ...form, firstName: event.target.value }))} className="mb-0" id="exampleInputEmail2" placeholder="First Name" autoComplete="off" required />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please enter the First Name.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group>
                                                        <Form.Label style={{ color: "white" }}>Last Name</Form.Label>
                                                        <Form.Control type="text" isInvalid={fieldFlag.lastName} onChange={(event) => (setForm({ ...form, lastName: event.target.value }))} className="mb-0" id="exampleInputEmail3" placeholder="Last Name" autoComplete="off" required />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please enter the Last Name.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group>
                                                        <Form.Label style={{ color: "white" }}>E-mail</Form.Label>
                                                        <Form.Control style={{ borderRadius: "1rem" }} type="email" isInvalid={fieldFlag.email} onChange={(event) => (setForm({ ...form, email: event.target.value }))} className="mb-0" id="exampleInputEmail3" placeholder="Enter email" autoComplete="off" required />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please enter the valid Email.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group>
                                                        <Form.Label style={{ color: "white" }}>Phone</Form.Label>
                                                        <FormControl fullWidth>
                                                            <PhoneInput
                                                                labelId="demo-simple-select-label"
                                                                country={"in"}
                                                                required={true}
                                                                // value={form?.phoneNumber}

                                                                onChange={(phone, countryCode) => {
                                                                    setForm({
                                                                        ...form,
                                                                        mobileNumber:
                                                                            // form?.countryCode == "+" + countryCode.dialCode

                                                                            (countryCode.dialCode == "91" ? (phone[2] >= 6 ? phone.substring(2) : countryCode.dialCode)
                                                                                : phone.substring(2)),
                                                                        countryCode: "+" + countryCode.dialCode,
                                                                    });
                                                                    console.log(form)
                                                                }}
                                                                isValid={(value, country) => {
                                                                    if (country.dialCode == "91" && value[2] < 6) {
                                                                        return 'Invalid Number'
                                                                    } else {
                                                                        return true;
                                                                    }
                                                                }}
                                                                dropdownClass={styles.phoneInputDropDown}
                                                                highlightClass={styles.highlight}
                                                                buttonClass={styles.phoneInputButton}
                                                                inputClass={styles.phoneInput}
                                                            />
                                                        </FormControl>
                                                    </Form.Group>
                                                </Col>
                                                {/* <Col md="4" className='mt-3'>
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={State.getAllStates().filter(state => state.countryCode === 'IN')}
                                                        getOptionLabel={(option) => option.name}
                                                        onChange={(event, value) => {
                                                            if (value !== null) {
                                                              // Perform actions when a state is selected
                                                              console.log("Selected State:", value);
                                                              setSelectedState(value)
                                                              // You can perform additional actions here, such as updating state or making API calls
                                                            }
                                                          }}
                                                        sx={{

                                                            borderRadius: "1rem",

                                                        }}
                                                        PaperComponent={({ children }) => (
                                                            <Paper style={{ backgroundColor: 'black', color: "#ffffff" }}>{children}</Paper>
                                                        )}
                                                        renderInput={(params) =>
                                                            <TextField
                                                                {...params}
                                                                label="Select State"
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    style: { borderColor: 'white', color: 'white' }, // Change border and value color
                                                                }}
                                                                InputLabelProps={{
                                                                    ...params.InputLabelProps,
                                                                    style: { color: 'white' }, // Change label color
                                                                }}
                                                            />}
                                                    />
                                                </Col>
                                                <Col md="4" className='mt-3'>
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={stateCity}
                                                        getOptionLabel={(option) => option.name}
                                                        onChange={(event, value) => {
                                                            if (value !== null) {
                                                              // Perform actions when a state is selected
                                                              console.log("Selected State:", value);
                                                              setSelectedState(value)
                                                              // You can perform additional actions here, such as updating state or making API calls
                                                            }
                                                          }}
                                                        sx={{

                                                            borderRadius: "1rem",

                                                        }}
                                                        PaperComponent={({ children }) => (
                                                            <Paper style={{ backgroundColor: 'black', color: "#ffffff" }}>{children}</Paper>
                                                        )}
                                                        renderInput={(params) =>
                                                            <TextField
                                                                {...params}
                                                                label="Select City"
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    style: { borderColor: 'white', color: 'white' }, // Change border and value color
                                                                }}
                                                                InputLabelProps={{
                                                                    ...params.InputLabelProps,
                                                                    style: { color: 'white' }, // Change label color
                                                                }}
                                                            />}
                                                    />
                                                </Col>
                                                <Col md="4" className='mt-3'>
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={stateCity}
                                                        getOptionLabel={(option) => option.name}
                                                        onChange={(event, value) => {
                                                            if (value !== null) {
                                                              // Perform actions when a state is selected
                                                              console.log("Selected State:", value);
                                                              setSelectedState(value)
                                                              // You can perform additional actions here, such as updating state or making API calls
                                                            }
                                                          }}
                                                        sx={{

                                                            borderRadius: "1rem",

                                                        }}
                                                        PaperComponent={({ children }) => (
                                                            <Paper style={{ backgroundColor: 'black', color: "#ffffff" }}>{children}</Paper>
                                                        )}
                                                        renderInput={(params) =>
                                                            <TextField
                                                                {...params}
                                                                label="Select Area"
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    style: { borderColor: 'white', color: 'white' }, // Change border and value color
                                                                }}
                                                                InputLabelProps={{
                                                                    ...params.InputLabelProps,
                                                                    style: { color: 'white' }, // Change label color
                                                                }}
                                                            />}
                                                    />
                                                </Col> */}
                                                                
                                                <Col md="6">
                                                    <Form.Group>
                                                        <Form.Label style={{ color: "white" }}>Password</Form.Label>
                                                        <InputGroup className="mb-3">
                                                            <Form.Control type="password" isInvalid={fieldFlag.password} onChange={(event) => (setForm({ ...form, password: event.target.value }))} className="promocode-input" id="password" placeholder="Password" required />
                                                            <Button variant="outline-secondary" onClick={(e) => {
                                                                let temp = document.getElementById("password")
                                                                if (temp.type == "password") {
                                                                    temp.type = "text"
                                                                    document.getElementById("password_icon").className = "fa fa-eye"
                                                                }
                                                                else {
                                                                    temp.type = "password"
                                                                    document.getElementById("password_icon").className = "fa fa-eye-slash"

                                                                }
                                                            }} className='promocode-button' style={{ backgroundColor: "black" }}>
                                                                <i className="fa fa-eye-slash" id='password_icon' style={{ color: "#ffffff" }}></i>
                                                            </Button>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please enter the password.
                                                            </Form.Control.Feedback>       </InputGroup>
                                                    </Form.Group>
                                                </Col>

                                                <Col md="6">
                                                    <Form.Group>
                                                        <Form.Label style={{ color: "white" }}>Confirm Password</Form.Label>
                                                        <InputGroup className="mb-3">
                                                            <Form.Control type="password" isInvalid={form?.password != form?.rpassword} onChange={(event) => (setForm({ ...form, rpassword: event.target.value }))} className="promocode-input" id="confirm_password" placeholder="Password" required />
                                                            <Button variant="outline-secondary" onClick={(e) => {
                                                                let temp = document.getElementById("confirm_password")
                                                                if (temp.type == "password") {
                                                                    temp.type = "text"
                                                                    document.getElementById("confirm_password_icon").className = "fa fa-eye"
                                                                }
                                                                else {
                                                                    temp.type = "password"
                                                                    document.getElementById("confirm_password_icon").className = "fa fa-eye-slash"

                                                                }
                                                            }} className='promocode-button' style={{ backgroundColor: "black", zIndex: "0" }}>
                                                                <i className="fa fa-eye-slash" id='confirm_password_icon' style={{ color: "#ffffff" }}></i>
                                                            </Button>
                                                            <Form.Control.Feedback type="invalid">
                                                                Passwords do not match.
                                                            </Form.Control.Feedback>       </InputGroup>
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            {register_message && register_message?.statuscode != 200 && messageTimeout && <Alert variant="danger" style={{ background: "rgb(255,0,0,0.1)", borderColor: "rgb(255,0,0)" }} >
                                                {/* <Alert.Heading style={{color:"rgb(255,0,0)",fontWeight:"500",fontSize:"18px"}}>Registration unsuccessful</Alert.Heading> */}
                                                <p style={{ color: "rgb(255,0,0)", fontWeight: "500", fontSize: "14px" }}>
                                                    {register_message?.message}
                                                </p>
                                            </Alert>}
                                            <div style={{ display: "flex" }}>
                                                <Button type='submit' className="btn btn-hover" disabled={loader ? true : false}>Sign Up</Button>
                                                {loader ? <CircularProgress /> : <></>}
                                            </div>

                                        </Form>}
                                    </div>
                                </div>
                                {!otpVerification && <div className="mt-3">
                                    <div className="d-flex justify-content-center links">
                                        Already have an account?
                                        <Link to="/login" className="text-primary ml-2">Sign In</Link>
                                    </div>
                                </div>}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)