import axios from "axios";
import {
  GET_MOVIE_DETAILS,
  GET_MOVIE_DETAILS_ERROR,
  GET_SLIDER_DATA,
  GET_SLIDER_DATA_ERROR,
  REGISTER_MESSAGE,
  OTP_MESSAGE,
  LOGIN_MESSAGE,
  PROFILE_MESSAGE,
  COMPLAINT_MESSAGE,
  AVATARS,
  PROFILES,
  PRIVACY_POLICY,
  TERMS_CONDITIONS,
  REFUND_POLICY,
  ABOUT_US,
  GET_ALL_HOME_DATA,
  GET_ALL_HOME_DATA_ERROR,
  EDIT_USER_DETAILS_MESSAGE,
  CHANGE_PASSWORD_MESSAGE,
  LOGOUT_MESSAGE,
  LIVE_STREAMING_DASHBOARD_DATA,
  LIVE_STREAMING_DASHBOARD_DATA_ERROR,
  GET_STREAM_DETAILS,
  GET_STREAM_DETAILS_ERROR,
  USER_DETAILS,
  FORGOT_PASSWORD_MESSAGE,
  NOTIFICATIONS,
  USER_LOGIN_LIST,
  USER_LOGOUT_MESSAGE,
  RUNNING_STATUS,
  FORGOT_PROFILE_PIN_MESSAGE,
  GET_ALL_CATEGORIES,
  GET_ALL_CATEGORIES_ERROR,
  GET_SUBCATEGORIES,
  GET_SUBCATEGORIES_ERROR,
  GET_ALL_VIDEOS_BY_SUBCATAGORY_ERROR,
  GET_ALL_VIDEOS_BY_SUBCATAGORY,
  VIDEO_SEEN_COUNT,
  VIDEO_SEEN_COUNT_ERROR,
  REDIRECT_VIDEO_COUNT,
  REDIRECT_VIDEO_COUNT_ERROR,
  DIGITAL_FORM,
  DIGITAL_FORM_ERROR
} from "../Actiontype/ActionType";
import { GET_MOVIE_DETAILS_API, GET_SLIDER_DATA_API, REGISTER_API, LOGIN_API, OTP_API, all_avatar_list_api, profile_create_api, user_profile_list_api, complaint_create_api, privacy_policy_api, terms_and_conditions_api, refund_policy_api, GET_ALL_HOME_DATA_API, edit_user_details_api, change_password_api, about_us_api, LOGOUT_API, LIVE_STREAMING_DASHBOARD_DATA_API, GET_STREAM_DETAILS_API, user_details_web_api, update_password_api, forgot_password_send_otp_api, forgot_password_verify_otp_api, resend_otp_api, user_notification_api, notification_read_status_update_api, user_login_list_api, user_logout_api, user_login_status_api, profile_edit_api, profile_delete_api, change_running_status_api, profile_pin_verification_api, forgot_profile_pin_api, GET_ALL_CATEGORIES_API, GET_SUBCATEGORIES_API, GET_ALL_VIDEOS_BY_SUBCATAGORY_API, VIDEO_SEEN_COUNT_API, REDIRECT_VIDEO_COUNT_API, GET_ALL_HIGHLIGHT_LIST_API, GET_ALL_PROMOTION_LIST_API, GET_ALL_TAGS_API, GET_ALL_LANGUAGE_API, DIGITAL_FORM_API, GET_VIDEOS_BY_TAGS, DIGITAL_COMPETITION_FORM_API } from "../constant/ApiList";
import { Base64 } from "js-base64";
import { ROUTES } from "../constant/routes";


export const getSliderData = () => {
  return async (dispatch) => {
    return await axios
      .post(GET_SLIDER_DATA_API)
      .then((res) => {
        dispatch({
          type: GET_SLIDER_DATA,
          payload: res.data
        });

        return res.data
      }).catch((error) => {
        dispatch({
          type: GET_SLIDER_DATA_ERROR
        });
        return error.response;
      })
  }
}
export const getAllCategories = () => {
  return async (dispatch) => {
    return await axios
      .post(GET_ALL_CATEGORIES_API)
      .then((res) => {
        dispatch({
          type: GET_ALL_CATEGORIES,
          payload: res.data
        });
        return res.data
      }).catch((error) => {
        dispatch({
          type: GET_ALL_CATEGORIES_ERROR
        });
        return error.response;
      })
  }
}

export const getSubCategory = (obj) => {
  return async (dispatch) => {
    return await axios
      .post(GET_SUBCATEGORIES_API, obj)
      .then((res) => {
        dispatch({
          type: GET_SUBCATEGORIES,
          payload: res.data
        });
        return res.data
      }).catch((error) => {
        dispatch({
          type: GET_SUBCATEGORIES_ERROR
        });
        return error.response;
      })
  }
}

export const getVideosBySubCategory = (obj) => {
  return async (dispatch) => {
    return await axios
      .post(GET_ALL_VIDEOS_BY_SUBCATAGORY_API, obj)
      .then((res) => {
        dispatch({
          type: GET_ALL_VIDEOS_BY_SUBCATAGORY,
          payload: res.data
        });
        return res.data
      }).catch((error) => {
        dispatch({
          type: GET_ALL_VIDEOS_BY_SUBCATAGORY_ERROR
        });
        return error.response;
      })
  }
}

export const VideoSeenCount = (obj) => {
  return async (dispatch) => {
    return await axios
      .post(VIDEO_SEEN_COUNT_API, obj)
      .then((res) => {
        dispatch({
          type: VIDEO_SEEN_COUNT,
          payload: res.data
        });
        return res.data
      }).catch((error) => {
        dispatch({
          type: VIDEO_SEEN_COUNT_ERROR
        });
        return error.response;
      })
  }
}

export const addtoRedirectCount = (obj) => {
  return async (dispatch) => {
    return await axios
      .post(REDIRECT_VIDEO_COUNT_API, obj)
      .then((res) => {
        dispatch({
          type: REDIRECT_VIDEO_COUNT,
          payload: res.data
        });
        return res.data
      }).catch((error) => {
        dispatch({
          type: REDIRECT_VIDEO_COUNT_ERROR
        });
        return error.response;
      })
  }
}

export const getMovieDetails = (slug) => {
  return async (dispatch) => {
    return await axios
      .post(`${GET_MOVIE_DETAILS_API}/${slug}/`)
      .then((res) => {
        dispatch({
          type: GET_MOVIE_DETAILS,
          payload: res.data
        });
        return res.data
      }).catch((error) => {
        dispatch({
          type: GET_MOVIE_DETAILS_ERROR
        });
        return error.response;
      })
  }
}

export const getStreamDetails = (slug, userObj) => {

}


export const register = (formData) => async (dispatch) => {
  try {
    const { data } = await REGISTER_API(formData);
    dispatch({ type: REGISTER_MESSAGE, payload: data });


  } catch (error) {
  }
};
export const resend_otp = (formData) => async (dispatch) => {
  try {
    const { data } = await resend_otp_api(formData);
    dispatch({ type: OTP_MESSAGE, payload: data });


  } catch (error) {
  }
};
export const user_details_web = (formData) => async (dispatch) => {

};
export const edit_user_details = (formData) => async (dispatch) => {
  try {
    const { data } = await edit_user_details_api(formData);
    dispatch({ type: EDIT_USER_DETAILS_MESSAGE, payload: data });


  } catch (error) {
  }
};
export const change_password = (formData) => async (dispatch) => {
  try {
    console.log("comes in forgot password", formData)
    const { data } = await change_password_api(formData);
    dispatch({ type: CHANGE_PASSWORD_MESSAGE, payload: data });
    return data

  } catch (error) {
    return error

  }
}; export const update_password = (formData) => async (dispatch) => {
  try {
    const { data } = await update_password_api(formData);
    dispatch({ type: FORGOT_PASSWORD_MESSAGE, payload: data });


  } catch (error) {
  }
}; export const forgot_password_send_otp = (formData) => async (dispatch) => {
  try {
    const { data } = await forgot_password_send_otp_api(formData);
    dispatch({ type: FORGOT_PASSWORD_MESSAGE, payload: data });
    return data

  } catch (error) {
  }
}; export const forgot_password_verify_otp = (formData) => async (dispatch) => {
  try {
    const { data } = await forgot_password_verify_otp_api(formData);
    if (data?.statuscode != 200)
      dispatch({ type: FORGOT_PASSWORD_MESSAGE, payload: data });
    else
      dispatch({ type: FORGOT_PASSWORD_MESSAGE, payload: { ...data, message: null } });

    return data
  } catch (error) {
  }
};



export const otp = (formData) => async (dispatch) => {
  try {
    const { data } = await OTP_API(formData);
    dispatch({ type: OTP_MESSAGE, payload: data });


  } catch (error) {
  }
};
export const user_login_list = (formData) => async (dispatch) => {
  try {
    const { data } = await user_login_list_api(formData);
    dispatch({ type: USER_LOGIN_LIST, payload: data });
    return data

  } catch (error) {
  }
};

export const user_logout = (formData) => async (dispatch) => {
  try {
    const { data } = await user_logout_api(formData);
    dispatch({ type: USER_LOGOUT_MESSAGE, payload: data });
    const { data1 } = await user_login_list_api(formData);
    dispatch({ type: USER_LOGIN_LIST, payload: data1 });

    return data

  } catch (error) {
  }
};

export const login = (formData, history) => async (dispatch) => {

  try {
    const { data } = await LOGIN_API(formData);
    if (data?.statuscode == 200) {
      sessionStorage.setItem("user", JSON.stringify(data?.data))
      sessionStorage.setItem("isLoggedIn", true)
      if (window.location.pathname == "/login") {
        history.push(ROUTES.home)
      }
      if (formData?.remember) {
        localStorage.setItem("login_details", JSON.stringify({ ...formData }))
      }
    }
    else {
      localStorage.setItem("login_details", "{}")

    }

    dispatch({ type: LOGOUT_MESSAGE, payload: null });
    dispatch({ type: LOGIN_MESSAGE, payload: data });


  } catch (error) {
  }
};
export const logout = (formData) => async (dispatch) => {
  try {
    const { data } = await LOGOUT_API(formData);
    sessionStorage.setItem("profile", "{}")
    sessionStorage.setItem("user", "{}")
    sessionStorage.setItem("isLoggedIn", false)
    localStorage.setItem("login_details", "{}")
    dispatch({ type: LOGOUT_MESSAGE, payload: data });
    dispatch({ type: LOGIN_MESSAGE, payload: null });


  } catch (error) {
  }
};
export const user_login_status = (formData) => async (dispatch) => {
  try {
    // const { data } = await user_login_status_api(formData);
    // if (data?.statuscode == 406) {
    //   sessionStorage.setItem("profile", "{}")
    //   sessionStorage.setItem("user", "{}")
    //   sessionStorage.setItem("isLoggedIn", false)
    //   localStorage.setItem("login_details", "{}")
    // }


  } catch (error) {
  }
};
export const all_avatar_list = (formData) => async (dispatch) => {

};
export const user_profile_list = (formData) => async (dispatch) => {

};
export const profile_create = (formData) => async (dispatch) => {

};
export const profile_pin_verification = (formData) => async (dispatch) => {

};
export const forgot_profile_pin = (formData) => async (dispatch) => {

};
export const profile_edit = (formData) => async (dispatch) => {

};
export const profile_delete = (formData) => async (dispatch) => {

};
export const change_running_status = (formData) => async (dispatch) => {

};
export const complaint_create = (formData) => async (dispatch) => {
  try {
    const { data } = await complaint_create_api(formData);
    dispatch({ type: COMPLAINT_MESSAGE, payload: data });


  } catch (error) {
  }
};
export const privacy_policy = (formData) => async (dispatch) => {
  try {
    const { data } = await privacy_policy_api(formData);
    dispatch({ type: PRIVACY_POLICY, payload: data });


  } catch (error) {
  }
};
export const terms_and_conditions = (formData) => async (dispatch) => {
  try {
    const { data } = await terms_and_conditions_api(formData);
    dispatch({ type: TERMS_CONDITIONS, payload: data });


  } catch (error) {
  }
};
export const refund_policy = (formData) => async (dispatch) => {
  try {
    const { data } = await refund_policy_api(formData);
    dispatch({ type: REFUND_POLICY, payload: data });


  } catch (error) {
  }
};
export const about_us = (formData) => async (dispatch) => {
  try {
    const { data } = await about_us_api(formData);
    dispatch({ type: ABOUT_US, payload: data });


  } catch (error) {
  }
};

export const user_notification = (formData) => async (dispatch) => {

};
export const notification_read_status_update = (formData) => async (dispatch) => {

};


export const getAllHomeData = (userObj) => {
  return async (dispatch) => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    // console.log({ ...userObj, ip_address: res.data.ip })
    return await axios
      .post(GET_ALL_HOME_DATA_API, { ...userObj, ip_address: res.data.ip })
      .then((res) => {
        dispatch({
          type: GET_ALL_HOME_DATA,
          payload: res?.data
          // payload: { ...res?.data, data: JSON.parse(Base64.decode(res?.data?.data)) }
        });
        // return { ...res?.data, data: JSON.parse(Base64.decode(res?.data?.data)) }
        // console.log(res)
        return res?.data
      }).catch((error) => {
        dispatch({
          type: GET_ALL_HOME_DATA_ERROR
        });
        return error.response;
      })
  }
}

export const live_streaming_dashboard = (userObj) => {

}
export const getHighlightList = (userObj) => {
  return async (dispatch) => {
    return await axios
      .post(GET_ALL_HIGHLIGHT_LIST_API, userObj).then((res) => {
        // console.log(res)
        return res.data
      }).catch((error) => {
        return error.response
      })
  }
}
export const getPromotionList = (userObj) => {
  return async (dispatch) => {
    return await axios
      .post(GET_ALL_PROMOTION_LIST_API, userObj).then((res) => {
        // console.log(res)
        return res.data
      }).catch((error) => {
        return error.response
      })
  }
}

export const getAllData = async () => {
  try {
    // Make multiple API requests concurrently
    const [res1, res2] = await Promise.all([
      axios.post(GET_ALL_TAGS_API),
      axios.post(GET_ALL_LANGUAGE_API),
    ]);
    let obj = {
      tags: res1?.data,
      language: res2?.data
    }

    return obj;
  } catch (error) {
    throw error;

  }
}

export const submitDigitalForm = (formData) => {
  return async (dispatch) => {
    return await axios
      .post(DIGITAL_FORM_API, formData)
      .then((res) => {
        dispatch({
          type: DIGITAL_FORM,
          payload: res?.data
        });

        return res?.data
      }).catch((error) => {
        dispatch({
          type: DIGITAL_FORM_ERROR
        });
        return error.response;
      })
  }
}
export const submitCompetitionForm = async(formData) => {
    return await axios
      .post(DIGITAL_COMPETITION_FORM_API, formData)
      .then((res) => {
        return res?.data
      }).catch((error) => {
        return error.response;
      })
  
}

export const getVideoByTags = async (userObj) => {
  return await axios
    .post(GET_VIDEOS_BY_TAGS, userObj).then((res) => {
      return res.data
    }).catch((error) => {
      return error.response
    })

}